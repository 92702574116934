import React, { FC } from "react";
import { ITemplateItem } from "dto/template.dto";
import TemplateItemGif from "components/Question/Template/TemplateItemGif";
import TemplateItemPng from "components/Question/Template/TemplateItemPng";
import TemplateItemLine from "./TemplateItemLine";
import TemplateItemPath from "./TemplateItemPath";
import TemplateItemRect from "./TemplateItemRect";
import TemplateItemRing from "./TemplateItemRing";
import TemplateItemStar from "./TemplateItemStar";
import TemplateItemText from "components/Question/Template/TemplateItemText";
import TemplateItemCircle from "./TemplateItemCircle";
import TemplateItemPathGroup from "./TemplateItemPathGroup";
import TemplateStageBackground from "components/Question/Template/TemplateStageBackground";
import TemplateItemRegularPolygon from "./TemplateItemRegularPolygon";

interface IProps {
  refStage?: any;
  templateItem: ITemplateItem;
  selectTemplateItem?: (templateItem: ITemplateItem) => void;
}

const TemplateItem: FC<IProps> = (props) => {
  return (
    <>
      {props.templateItem?.type === "line" ? (
        <TemplateItemLine templateItem={props.templateItem} />
      ) : props.templateItem?.type === "path" ? (
        <TemplateItemPath templateItem={props.templateItem} />
      ) : props.templateItem?.type === "rect" ? (
        <TemplateItemRect templateItem={props.templateItem} />
      ) : props.templateItem?.type === "ring" ? (
        <TemplateItemRing templateItem={props.templateItem} />
      ) : props.templateItem?.type === "star" ? (
        <TemplateItemStar templateItem={props.templateItem} />
      ) : props.templateItem?.type === "circle" ? (
        <TemplateItemCircle templateItem={props.templateItem} />
      ) : props.templateItem?.type === "image/gif" ? (
        <TemplateItemGif 
          refStage={props.refStage}
          templateItem={props.templateItem} 
          selectTemplateItem={props.selectTemplateItem}
        />
      ) : props.templateItem?.type === "image/png" ? (
        <TemplateItemPng 
          refStage={props.refStage}
          templateItem={props.templateItem}
          selectTemplateItem={props.selectTemplateItem}
        />
      ) : props.templateItem?.type === "text/text" ? (
        <TemplateItemText 
          refStage={props.refStage}
          templateItem={props.templateItem}
          selectTemplateItem={props.selectTemplateItem}
        />
      ) : props.templateItem?.type === "path-group" ? (
        <TemplateItemPathGroup templateItem={props.templateItem} />
      ) : props.templateItem?.type === "regular-polygon" ? (
        <TemplateItemRegularPolygon templateItem={props.templateItem} />
      ) : (
        <TemplateStageBackground width={100} height={100} />
      )}
    </>
  )
}

export default TemplateItem;
