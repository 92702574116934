import React, { createRef, FC, useEffect, useState } from "react";
import { ITemplateItem } from "dto/template.dto";
import { IQuestion, IQuestionAnswer, IQuestionFile, IQuestionPreview } from "dto/question.dto";
import { questionAnswersInit, questionFilesInit } from "initials";
import * as utils from "utils/question.utils";
import { templateInit } from "initials/template.init";
import Notebook from "components/Notebook/Notebook";
import Question from "components/Question/Question";
import DialogWarning from "components/DialogCommon/DialogWarning";
import NotebookActions from "components/Notebook/NotebookActions";
import NotebookContent from "components/Notebook/NotebookContent";

interface IProps {
  path: string;
  zoom?: boolean;
  loading?: boolean;
  questions: IQuestion[];
  headerColor: string;
  languageAudio: string;
  backgroundColor?: string;
  progressBarColor: string;
  buttonNextColorText?: string;
  handleAnalytics: (name: string, params?: { [key: string]: string; }) => void;
  buttonZoomOnClick: () => void;
  updateQuestionIndex?: (index: number) => void;
  saveQuestionsAnswers: (q: IQuestion[], f: IQuestionFile[], a: IQuestionAnswer[]) => void;
  closeQuestionNotebook: () => void;
}

const QuestionNotebook: FC<IProps> = (props) => {
  const audioRef = createRef<HTMLAudioElement>();
  const [audioSrc, setAudioSrc] = useState("");
  const [question, setQuestion] = useState<IQuestion | undefined>(undefined);
  const [answerRating, setAnswerRating] = useState(false);
  const [viewFeedback, setViewFeedback] = useState(false);
  const [dialogWarning, setDialogWarning] = useState(false);
  const [pdfNumberPage, setPdfNumberPage] = useState(1);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [templateIndex, setTemplateIndex] = useState(0);
  const [pdfNumberPages, setPdfNumberPages] = useState(0);
  const [questionsFiles, setQuestionsFiles] = useState(questionFilesInit);
  const [questionLoading, setQuestionLoading] = useState(false);
  const [questionPreview, setQuestionPreview] = useState<IQuestionPreview | undefined>(undefined);
  const [skipLogicIndexs, setSkipLogicIndexs] = useState<number[]>([]);
  const [timeoutDisabled, setTimeoutDisabled] = useState(false);
  const [buttonSoundImage, setButtonSoundImage] = useState("/img/sound.png");
  const [questionsAnswers, setQuestionsAnswers] = useState(questionAnswersInit);
  const templates = question?.templates ?? [];
  const templateCurrent = templates[templateIndex] ?? templateInit;
  const buttonNextDisabledType23 = question?.type === 23 
    && !!templateCurrent?.timeout
    && templateCurrent?.timeout > 0
    && !!templateCurrent?.disable_continue;
  const buttonNextDisabled = !!questionLoading || !!timeoutDisabled || !!buttonNextDisabledType23;
  const dialogWarningSubtitle = question?.type === 21 
    ? "Todavía te quedan páginas por leer en este documento. ¿Deseas continuar a la siguiente actividad?" 
    : "";
  document.querySelector("#lab_question_audio")?.addEventListener(
    "ended",
    () => setButtonSoundImage("/img/sound.png"), 
    false,
  );

  useEffect(() => {
    setAnswerRating(false);
    setViewFeedback(false);
    setQuestionIndex(0);
    setTemplateIndex(0);
    setQuestionsFiles(utils.getQuestionFiles(props.questions));
    setQuestionsAnswers(utils.getQuestionAnswers(props.questions));
    !!props.updateQuestionIndex && props.updateQuestionIndex(0);
    if (!!props.questions?.length) {
      getQuestionPreview(props.questions[0]);
      getQuestionFullScreen(props.questions[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.questions]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!!question?.timeout) {
      const { type, timeout } = question;
      if (type === 22 || type === 25) {
        setTimeoutDisabled(true);
        timer = setTimeout(() => setTimeoutDisabled(false), (timeout * 60 * 1000));
      }
    }

    if (!!question?.audio_autoplay) buttonAudioOnClick();

    return () => {
      clearTimeout(timer);
      setTimeoutDisabled(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (templateCurrent?.timeout === -1) {
      
    } else if (!!templateCurrent?.timeout) {
      const { timeout } = templateCurrent;
      timer = setTimeout(() => nextQuestion(), (timeout * 1000));
    }

    return () => {
      clearTimeout(timer);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateCurrent]);

  const getQuestionPreview = async (value: IQuestion) => {
    setQuestionLoading(true);
    const preview = await utils.getQuestionPreview(value, props.path);
    if (value?.type === 21) {
      const audios = preview?.pdf_audios ?? [];
      const audioUrl = getAudioCurrent(audios[0]?.audio, audios[0]?.audio_wa, props.languageAudio);
      setAudioSrc(audioUrl);
    } else if (value?.type === 23) {
      const templates = value?.templates ?? [];
      const audio_url = templates[0]?.audio_url ?? "";
      setAudioSrc(audio_url);
    } else {
      const audioUrl = getAudioCurrent(preview.audio, preview.audio_wa, props.languageAudio)
      setAudioSrc(audioUrl);
    }
    setPdfNumberPage(1);
    setTemplateIndex(0);
    setPdfNumberPages(0);
    setQuestion(value);
    setQuestionPreview(preview);
    setQuestionLoading(false);
  }
  const getQuestionFullScreen = (qnew: IQuestion, old?: IQuestion) => {
    if (qnew?.type === 22 && !!qnew?.full_screen && !props.zoom) {
      props.buttonZoomOnClick();
    } else if (old?.type === 22 && !!old?.full_screen && !!props.zoom) {
      props.buttonZoomOnClick();
    }
  }

  const audioPause = () => {
    setButtonSoundImage("/img/sound.png");
    if (!!audioSrc) audioRef?.current?.pause();
  }
  const buttonAudioOnClick = () => {
    if (!!audioSrc && !!audioRef?.current?.paused) {
      audioRef?.current?.play();
      setButtonSoundImage("/img/pause.png");
      props.handleAnalytics("play_laboratory_question_audio");
    } else if (!!audioSrc && !audioRef?.current?.paused)  {
      audioRef?.current?.pause();
      setButtonSoundImage("/img/play.png");
    } else setButtonSoundImage("/img/sound.png");
  }

  const openDialogWarning = () => setDialogWarning(true);
  const closeDialogWarning = () => setDialogWarning(false);
  const acceptDialogWarning = () => {
    nextQuestionSave();
    closeDialogWarning();
  }

  const selectQuestionFile = (value: File, path: string) => {
    const files = questionsFiles?.map((questionFile, i) => (
      questionIndex === i ? { file: value, path } : questionFile
    ));
    setQuestionsFiles(files);
  }
  const selectQuestionAnswer = (value: string) => {
    const answers = questionsAnswers.map((questionAnswer, i) => (
      questionIndex === i ? { ...questionAnswer, answer: value } : questionAnswer
    ));
    setAnswerRating(utils.getAnswerRating(value, question));
    setQuestionsAnswers(answers);
  }

  const buttonPagePrevOnClick = () => {
    if (question?.type === 21 && pdfNumberPage > 1) {
      audioPause();
      setPdfNumberPage(pdfNumberPage - 1);
      const audios = questionPreview?.pdf_audios ?? [];
      const audioUrl = getAudioCurrent(
        audios[pdfNumberPage - 2]?.audio,
        audios[pdfNumberPage - 2]?.audio_wa,
        props.languageAudio,
      );
      setAudioSrc(audioUrl);
    } 
  }
  const buttonPageNextOnClick = () => {
    if (question?.type === 21 && pdfNumberPage < pdfNumberPages) {
      audioPause();
      setPdfNumberPage(pdfNumberPage + 1);
      const audios = questionPreview?.pdf_audios ?? [];
      const audioUrl = getAudioCurrent(
        audios[pdfNumberPage]?.audio,
        audios[pdfNumberPage]?.audio_wa,
        props.languageAudio,
      );
      setAudioSrc(audioUrl);
    } 
  }

  const prevQuestion = () => {
    audioPause();
    if (question?.type === 23 && templateIndex > 0) {
      const templates = question?.templates ?? [];
      const templateAudioUrl = templates[templateIndex - 1]?.audio_url ?? "";
      setAudioSrc(templateAudioUrl);
      setTemplateIndex(templateIndex - 1);
    } else prevQuestionSave();
  }  
  const nextQuestion = () => {
    audioPause();
    if (!!question) {
      const { type, feedback, templates, modal_warning } = question;
      const withAnswerExact = !utils.getQuestionWithoutAnswerExact(question);
      if (type === 21 && pdfNumberPage < pdfNumberPages) {
        openDialogWarning();
      } else if ((type === 22 || type === 25) && !!modal_warning) {
        openDialogWarning();
      } else if (type === 23 && !!templates?.length && (templateIndex + 1) < templates?.length) {
        const templateAudioUrl = templates[templateIndex + 1]?.audio_url ?? "";
        setAudioSrc(templateAudioUrl);
        setTemplateIndex(templateIndex + 1);
      } else if (!!feedback && !!withAnswerExact && !viewFeedback) {
        setViewFeedback(true);
      } else nextQuestionSave();
    }
  }

  const prevQuestionSave = () => {
    const skipLogicIndex = skipLogicIndexs[skipLogicIndexs?.length - 1];
    const skipLogicIndexsNew = skipLogicIndexs?.filter((q, i) => (i !== skipLogicIndexs?.length - 1));
    if (!!skipLogicIndex || skipLogicIndex === 0) {
      setQuestionIndex(skipLogicIndex);
      getQuestionPreview(props.questions[skipLogicIndex]);
      getQuestionFullScreen(props.questions[skipLogicIndex], props.questions[questionIndex]);
      !!props.updateQuestionIndex && props.updateQuestionIndex(skipLogicIndex);
    } else props.closeQuestionNotebook();
    setAnswerRating(false);
    setViewFeedback(false);
    setPdfNumberPage(1);
    setPdfNumberPages(0);
    setSkipLogicIndexs(skipLogicIndexsNew);
  }
  const nextQuestionSave = () => {
    const skipLogicIndex = utils.getQuestionSkipLogic(
      props.questions, 
      question, 
      questionsAnswers[questionIndex],
    );
    const skipLogicIndexsNew = skipLogicIndexs;
    skipLogicIndexsNew.push(questionIndex);
    if (skipLogicIndex === questionIndex) {
      props.saveQuestionsAnswers(props.questions, questionsFiles, questionsAnswers);
    } else if (!!skipLogicIndex || skipLogicIndex === 0) {
      setQuestionIndex(skipLogicIndex);
      getQuestionPreview(props.questions[skipLogicIndex]);
      getQuestionFullScreen(props.questions[skipLogicIndex], props.questions[questionIndex]);
      !!props.updateQuestionIndex && props.updateQuestionIndex(skipLogicIndex);
    } else if ((props.questions.length - 1) > questionIndex) {
      setQuestionIndex(questionIndex + 1);
      getQuestionPreview(props.questions[questionIndex + 1]);
      getQuestionFullScreen(props.questions[questionIndex + 1], props.questions[questionIndex]);
      !!props.updateQuestionIndex && props.updateQuestionIndex(questionIndex + 1);
    } else props.saveQuestionsAnswers(props.questions, questionsFiles, questionsAnswers);
    setAnswerRating(false);
    setViewFeedback(false);
    setPdfNumberPage(1);
    setPdfNumberPages(0);
    setSkipLogicIndexs(skipLogicIndexsNew);
    props.handleAnalytics("perform_laboratory_question");
  }

  const getTemplateSkipLogic = (templateItem: ITemplateItem) => {
    const templates = question?.templates ?? [];
    if (!!templateItem?.skip_logic && !!templates?.length) {
      let templateIndexNew: number | undefined = undefined;
      for (let i = 0; i < templates?.length; i++) {
        if (templates[i]?.id === templateItem?.skip_logic) templateIndexNew = i;
      }
      if ((!!templateIndexNew || templateIndexNew === 0) && templateIndexNew === templateIndex) {
        nextQuestionSave();
      } else if (!!templateIndexNew || templateIndexNew === 0) {
        setTemplateIndex(templateIndexNew);
      }
    }
  }

  const getAudioCurrent = (audio?: string, audioWa?: string, language?: string) => {
    const audioUrl = language === "1" && !!audioWa 
      ? audioWa
      : language !== "1" && !!audio 
      ? audio
      : "";
    return audioUrl;
  }

  return (
    <Notebook
      zoom={props.zoom} 
      loading={!!props.loading || questionLoading}
      backgroundColor={props.backgroundColor}
    >
      <NotebookContent zoom={props.zoom}>
        {!!question && !!questionPreview && (
          <Question
            book
            zoom={props.zoom}
            answer={questionsAnswers[questionIndex]?.answer}
            question={question}
            headerColor={props.headerColor}
            answerRating={answerRating}
            questionFile={questionsFiles[questionIndex]}
            viewFeedback={viewFeedback}
            languageAudio={props.languageAudio}
            pdfPageNumber={pdfNumberPage}
            templateIndex={templateIndex}
            questionNumber={questionIndex + 1}
            questionsTotal={props.questions?.length ?? 0}
            questionPreview={questionPreview}
            progressBarColor={props.progressBarColor}
            selectFile={selectQuestionFile}
            selectAnswer={selectQuestionAnswer}
            getTemplateSkipLogic={getTemplateSkipLogic}
            updatePdfNumberPages={setPdfNumberPages}
          />
        )}
      </NotebookContent>
      <NotebookActions
        zoom={props.zoom}
        numberPage={pdfNumberPage}
        numberPages={pdfNumberPages}
        buttonAudioImage={buttonSoundImage}
        buttonNextDisabled={buttonNextDisabled}
        buttonNextColorText={props.buttonNextColorText}
        buttonBackOnClick={prevQuestion}
        buttonNextOnClick={nextQuestion}
        buttonZoomOnClick={props.buttonZoomOnClick}
        buttonAudioOnClick={!!audioSrc ? buttonAudioOnClick : undefined}
        buttonPagePrevOnClick={buttonPagePrevOnClick}
        buttonPageNextOnClick={buttonPageNextOnClick}
      />
      <DialogWarning
        open={dialogWarning}
        title="¿Continuar a la siguiente actividad?"
        subtitle={dialogWarningSubtitle}
        onClose={closeDialogWarning}
        onClickAccept={acceptDialogWarning}
      />
      <audio id="lab_question_audio" ref={audioRef} src={audioSrc}>
        Your browser does not support the <code>audio</code> element.
      </audio>
    </Notebook>
  )
}

export default QuestionNotebook;
