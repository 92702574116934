import React, { FC } from "react";
import Marquee from "react-fast-marquee";
import { makeStyles } from "@material-ui/core/styles";
import { PropTypes, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: { color: "#5f5f5f" },
  marquee: {
    "& .rfm-marquee": { marginRight: 4 },
  },
}));

interface IProps {
  play?: boolean;
  align?: PropTypes.Alignment
  color?: string;
  noWrap?: boolean
  variant?: "h5" | "h6" | "body1" | "body2" | "caption",
  marquee?: boolean;
  fontSize?: number | string;
  fontWeight?: number;
  lineHeight?: number;
  gutterBottom?: boolean;
}

const FormTypography: FC<IProps> = (props) => {
  const classes = useStyles();
  const style = {
    color: props.color,
    fontSize: props.fontSize,
    fontWeight: props.fontWeight,
    lineHeight: props.lineHeight,
  }

  return (
    <>
      {!!props.marquee ? (
        <Marquee className={classes.marquee} play={props.play}>
          <Typography 
            className={classes.root} 
            align={props.align}
            style={style} 
            noWrap={props.noWrap}
            variant={props.variant}
            gutterBottom={props.gutterBottom}
          >
            {props.children}
          </Typography>
        </Marquee>
      ) : (
        <Typography 
          className={classes.root} 
          align={props.align}
          style={style} 
          noWrap={props.noWrap}
          variant={props.variant}
          gutterBottom={props.gutterBottom}
        >
          {props.children}
        </Typography>
      )}
    </>
  )
}

export default FormTypography;
