import { Router } from "react-typesafe-routes";
import Home from "pages/Home";
import Login from "pages/LoginPage";
import Plans from "pages/PlansPage";
import SignIn from "pages/SignInPage";
import Survey from "pages/SurveyPage";
import Explore from "pages/ExplorePage";
import Profile from "pages/ProfilePage";
import Landing from "pages/LandingPage";
import Page404 from "pages/Page404Page";
import Donation from "pages/DonationPage";
import Laboratory from "pages/LaboratoryPage";
import PlansPrices from "components/Payment/PlansPrices/PlansPrices";
import SurveyPublic from "pages/SurveyPublicPage";
import TermsService from "pages/TermsServicePage";
import AnonymousUser from "pages/AnonymousUserPage";
import CreateAccount from "pages/CreateAccountPage";
import ExploreSurvey from "pages/ExploreSurveyPage";
import PrivacyPolicy from "pages/PrivacyPolicyPage";
import LaboratoryPublic from "pages/LaboratoryPublicPage";
import AnonymousUserTest from "pages/AnonymousUserTestPage";
import ExploreLaboratory from "pages/ExploreLaboratoryPage";
import SurveyWithExpirationTime from "pages/SurveyWithExpirationTime";
import LaboratoryWithExpirationTime from "pages/LaboratoryWithExpirationTime";

export const router = Router((route) => ({
  a001: route("/", { component: Login }), 
  a002: route("home", { component: Home }), 
  a003: route("plans", { component: Plans }), 
  a004: route("sign-in", { component: SignIn }), 
  a005: route("explore", { component: Explore }), 
  a006: route("profile", { component: Profile }), 
  a007: route("landing", { component: Landing }),
  a008: route("donation", { component: Donation }),
  a009: route("terms-service", { component: TermsService }),
  a010: route("privacy-policy", { component: PrivacyPolicy }),
  a011: route("plans-checkout", { component: PlansPrices }),
  a012: route("create-account", { component: CreateAccount }),
  a013: route("anonymous-user", { component: AnonymousUser }),
  a014: route("anonymous-user/test", { component: AnonymousUserTest }),
  // @ts-ignore
  a015: route("survey/:survey_uuid", { component: Survey }), 
  // @ts-ignore
  a016: route("survey/public/:survey_uuid", { component: SurveyPublic }), 
  a017: route(
    "survey-with-expiration-time/:expiration_time/:survey_uuid", 
    // @ts-ignore
    { component: SurveyWithExpirationTime }
  ),
  // @ts-ignore
  a018: route("lab/:laboratory_key", { component: LaboratoryPublic }),
  // @ts-ignore
  a019: route("laboratory/:laboratory_key", { component: Laboratory }),
  // @ts-ignore
  a20: route("laboratory/draft/:laboratory_key", { component: LaboratoryPublic }),
  a21: route(
    "laboratory-with-expiration-time/:expiration_time/:laboratory_uuid", 
    // @ts-ignore
    { component: LaboratoryWithExpirationTime }
  ),
  // @ts-ignore
  a22: route("laboratory/public/:laboratory_key", { component: LaboratoryPublic }),
  // @ts-ignore
  a23: route("explore/survey/:survey_uuid", { component: ExploreSurvey }),
  // @ts-ignore
  a24: route("explore/laboratory/:laboratory_key", { component: ExploreLaboratory }),
  b25: route("*", { component: Page404 }),
}));
