import React, { FC,  useRef } from "react";
import { Ring } from "react-konva";
import { ITemplateItem } from "dto/template.dto";

interface IProps {
  templateItem: ITemplateItem;
}

const TemplateItemRing: FC<IProps> = (props) => {
  const refRing = useRef<any>();

  return (
    <Ring
      x={props.templateItem.x}
      y={props.templateItem.y}
      id={props.templateItem.id}
      ref={refRing}
      fill={props.templateItem?.fill}
      width={props.templateItem.width}
      height={props.templateItem.height}
      scaleX={props.templateItem?.scaleX}
      scaleY={props.templateItem?.scaleY}
      innerRadius={props.templateItem?.inner_radius ?? 0}
      outerRadius={props.templateItem?.outer_radius ?? 0}
    />
  );
};

export default TemplateItemRing;
