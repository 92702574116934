import React, { FC, useRef } from "react";
import { Stage, Layer } from "react-konva";
import { ITemplate, ITemplateItem } from "dto/template.dto";
import TemplateItem from "components/Template/TemplateItem/TemplateItem";
import TemplateStageBackground from "./TemplateStageBackground";

interface IProps {
  template: ITemplate;
  selectTemplateItem?: (templateItem: ITemplateItem) => void;
}

const TemplateStage: FC<IProps> = (props) => {
  const refStage = useRef<any>();

  return (
    <Stage
      ref={refStage}
      width={props.template?.width}
      height={props.template?.height}
    >
      <Layer>
        <TemplateStageBackground
          fill={props.template?.fill}
          width={props.template?.width} 
          height={props.template?.height}
        >
          {props.template?.template_items?.map((templateItem, key) => (
            <TemplateItem 
              key={key}
              refStage={refStage}
              templateItem={templateItem}
              selectTemplateItem={props.selectTemplateItem}
            />
          ))}
        </TemplateStageBackground>
      </Layer>
    </Stage>
  )
}

export default TemplateStage;
