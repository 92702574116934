import React, { FC } from "react";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { ILaboratoryCongratulations } from "dto/laboratory.dto";
import { adjustBrightness } from "utils";
import FormButton from "components/Form/FormButton";
import FormTypography from "components/Form/FormTypography";

const useStyles = makeStyles((theme) => ({
  content: {
    gap: 20,
    height: "calc(100% - 110px)",
    padding: 32,
    textAlign: "center",
    alignItems: "center" ,
  },
  image: { 
    height: "calc(100% - 130px)",
    justifyContent: "center", 
    "& img": { maxWidth: "100%", maxHeight: "100%" },
    "&.large": { height: "calc(100% - 62px)" } 
  },
}));

interface IProps {
  success?: boolean;
  primaryColor?: string;
  challengerLast?: boolean;
  congratulations: ILaboratoryCongratulations;
  challengerNextName?: string;
  saveUserTask: () => void;
}

const SuccessContent: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const classImage = clsx(classes.image, !!props.challengerLast && "large");
  const color = !!props.primaryColor ? props.primaryColor : "#ff6300"
  const image = !!props.success
    ? props.congratulations?.complete_image 
    : props.congratulations.incomplete_image;
  
  return (
    <Grid className={classes.content} container>
      <Grid className={classImage} container>
        <img src={image} alt="" />
      </Grid>
      {!props.challengerLast && (
        <Grid item xs={12}>
          <FormTypography color="#8d8d8d">
            {t("Has desbloqueado la siguiente unidad")}
          </FormTypography>
          <FormTypography color="#8d8d8d" fontWeight={700}>
            {props.challengerNextName}
          </FormTypography>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormButton
          primary
          minWidth={296}
          boxShadow={`0px 4px 0px 0px ${adjustBrightness(color, 30)}`}
          backgroundColor={props.primaryColor}
          onClick={props.saveUserTask}
        >
          {t("Continuar")}
        </FormButton>
      </Grid>
    </Grid>
  )
}

export default SuccessContent;
