import React, { FC, useRef } from "react";
import { Star } from "react-konva";
import { ITemplateItem } from "dto/template.dto";

interface IProps {
  templateItem: ITemplateItem;
}

const TemplateItemStar: FC<IProps> = (props) => {
  const refStar = useRef<any>();

  return (
    <Star
      x={props.templateItem.x}
      y={props.templateItem.y}
      id={props.templateItem.id}
      ref={refStar}
      fill={props.templateItem?.fill}
      rotation={props.templateItem?.rotation}
      numPoints={props.templateItem?.num_points ?? 0}
      innerRadius={props.templateItem?.inner_radius ?? 0}
      outerRadius={props.templateItem?.outer_radius ?? 0}
    />
  );
};

export default TemplateItemStar;
