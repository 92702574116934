import React, { FC, useRef } from "react";
import { Group, Path } from "react-konva";
import { ITemplateItem } from "dto/template.dto";

interface IProps {
  templateItem: ITemplateItem;
}

const TemplateItemPathGroup: FC<IProps> = (props) => {
  const refGroup = useRef<any>();
  const paths = props.templateItem?.path_group ?? [];

  const getFill = (key: number, fill?: string) => {
    const colors = props.templateItem?.colors ?? [];
    const color = colors[key];
    return !!color
      ? color
      : !!props.templateItem?.fill
      ? props.templateItem?.fill
      : fill;
  };

  return (
    <Group
      x={props.templateItem.x}
      y={props.templateItem.y}
      id={props.templateItem.id}
      ref={refGroup}
      scaleX={props.templateItem?.scaleX}
      scaleY={props.templateItem?.scaleY}
    >
      {paths?.map((path, key) => (
        <Path
          x={path?.x}
          y={path?.y}
          key={key}
          data={path?.path}
          stroke={path?.stroke}
          strokeWidth={path?.stroke_width}
          fill={getFill(key, path?.fill)}
        />
      ))}
    </Group>
  );
};

export default TemplateItemPathGroup;
