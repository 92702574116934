import Survey from "services/survey.services";
import { ISurvey } from "dto/survey.dto";
import { surveyInit } from "initials";

export const getSurveyByUuid = async (surveyUuid: string) => {
  try {
    const survey: ISurvey = await Survey.getSurveyByUuid(surveyUuid);
    if (!!survey && survey.update_date > new Date("01/31/2024").getTime()) {
      const questions = survey?.questions?.filter((q) => (q?.type !== 19 && q.type !== 24));
      return { ...survey, questions }
    } else if (!!survey) {
      return survey;
    } else return surveyInit;
  } catch (error) {
    return surveyInit;
  }
}