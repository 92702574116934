import { PDFDocument } from "pdf-lib";
import User from "services/user.services";
import Files from "services/files.services";
import FbAdmin from "services/fb-admin.services";
import { IUser } from "dto/user.dto";
import { ICountry } from "dto/country.dto";
import { messageError } from "components/Common/Toast";
import { months, monthsMin } from "initials";

export * from "./rol.utils";
export * from "./note.utils";
export * from "./task.utils";
export * from "./user.utils";
export * from "./email.utils";
export * from "./audios.utils";
export * from "./colors.utils";
export * from "./entity.utils";
export * from "./module.utils";
export * from "./survey.utils";
export * from "./content.utils";
export * from "./checkout.utils";
export * from "./question.utils";
export * from "./template.utils";
export * from "./constants.utils";
export * from "./challenger.utils";
export * from "./laboratory.utils";
export * from "./entity-code.utils";

export const button = new Audio("/audios/button.wav");
export const soundButton = () => button.play();
export const change = new Audio("/audios/change.wav");
export const soundChange = () => change.play();

export const getDataLis = (db: any[]) => {
  if (!!db) {
    const data = Object.keys(db).map((key: any) => db[key].value);
    return data;
  } else return [];
}
export const deleteFile = async (path: string, fileName: string) => {
  try {
    if (!!fileName) {
      const ref = `${path}/${fileName}`;
      await Files.deleteFile({ ref });
    }
  } catch (error) { }
}
export const uploadFile = async (path: string, file: File, fileName?: string) => {
  try {
    if (!!file?.name && !!fileName) {
      const ref = `${path}/${fileName}`;
      await Files.uploadFile(ref, file);
    } else if (!!file?.name) {
      const ref = `${path}/${file.name}`;
      await Files.uploadFile(ref, file);
    }
  } catch (error) { }
}
export const downloadFile = async (path: string, fileName?: string, fileDefault?: string) => {
  if (!!fileName) {
    try {
      const ref = `${path}/${fileName}`;
      const result: string = await Files.downloadFile({ ref });
      if (!!result) return result;
      else if (!!fileDefault) return fileDefault;
      else return "";
    } catch (error) {
      if (!!fileDefault) return fileDefault;
      else return "";
    }
  } else if (!!fileDefault) {
    return fileDefault;
  } else return "";
}

export const verifiedRepeatNit = async (nit: string) => {
  const user: IUser = await User.getUserByNit(nit);
  if (!!user && !!user.nit && user.nit === nit) {
    messageError(("Existe un usuario con esa identificación"))
    return true
  }
  return false;
}

export const checkRepeatEmail = async (email: string) => {
  const resp = await FbAdmin.verifiedEmail(email);
  if (!!resp && !!resp.verified) return false;
  else {
    messageError("Existe un usuario con ese correo electrónico");
    return true;
  }
}

export const checkRepeatPhone = async (phone: string) => {
  const resp = await FbAdmin.verifiedPhone(phone);
  if (!!resp && !!resp.verified) return false;
  else {
    messageError(("El usuario ya está registrado"));
    return true;
  }
}

export const getMonthAndYear = (date: string) => {
  const newDate = new Date(date);
  if (!!newDate) {
    return { 
      year: newDate.getFullYear(),
      month: months[newDate.getMonth()],
    }
  } else return { month: "", year: "" }
}

export const getBirthdate = (date: string) => {
  const dateAux = new Date(date);
  if (!!dateAux) {
    return `${dateAux.getDate()} ${
      monthsMin[dateAux.getMonth()]
    }, ${dateAux.getFullYear()}`;
  } else return "dd mm, aaa";
}
export const getGenderLabel = (sex: string) => {
  const sexAux = sex.toLowerCase();
  if (sexAux === "hombre" || sexAux === "masculino" || sexAux === "male") {
    return "Masculino";
  } else if (sexAux === "mujer" || sexAux === "femenino" || sexAux === "female") {
    return "Femenino";
  } else if (sexAux === "noresponde" || sexAux === "prefiero_no_responder") {
    return "Prefiero no responder"
  } else return "Otro";
}
export const getGenderValue = (sex: string) => {
  const sexAux = sex.toLowerCase();
  if (sexAux === "hombre" || sexAux === "masculino" || sexAux === "male") {
    return "male";
  } else if (sexAux === "mujer" || sexAux === "femenino" || sexAux === "female") {
    return "female";
  } else if (sexAux === "noresponde" || sexAux === "prefiero_no_responder") {
    return "prefiero_no_responder"
  } else return "other";
}
export const getNationalityLabel = (countries: ICountry[], id: string) => {
  const nationality = id?.toLowerCase();
  const country = countries.filter((c) => (c.id === nationality))[0];
  return country?.label ?? "N/A";
}

export const getMinAge = (date: Date | string, minAge: number) => {
  const dateInit = new Date(date).getTime();
  const dateCurrent = new Date().getTime();
  const difference = (dateCurrent - dateInit) / (1000 * 60 * 60 * 24);
  return difference > minAge * 365;
}

export const adjustBrightness = (hexadecimal: string, percent: number) => {
  const rgb = hexadecimalToRGB(hexadecimal);
  const factor = 1 - percent / 100;
  const adjustedR = Math.round(rgb.r * factor);
  const adjustedG = Math.round(rgb.g * factor);
  const adjustedB = Math.round(rgb.b * factor);
  const adjustedHex = `#${`${adjustedR.toString(16).padStart(2, "0")}${adjustedG
    .toString(16)
    .padStart(2, "0")}${adjustedB
    .toString(16)
    .padStart(2, "0")}`.toUpperCase()}`;
  return adjustedHex;
}

const hexadecimalToRGB = (hexadecimal: string) => {
  hexadecimal = hexadecimal.replace("#", "");
  const r = parseInt(hexadecimal.substring(0, 2), 16);
  const g = parseInt(hexadecimal.substring(2, 4), 16);
  const b = parseInt(hexadecimal.substring(4, 6), 16);
  return { r, g, b };
}

export const convertBase64ToPdf = async (base64: string, fileName: string) => {
  const pdfDoc = await PDFDocument.create();
  const pageWidth = 755;
  const pageHeight = 535;
  const imageBytes = atob(base64.split(",")[1]);
  const imageUint8Array = new Uint8Array(imageBytes.length);

  for (let i = 0; i < imageBytes.length; i++) {
    imageUint8Array[i] = imageBytes.charCodeAt(i);
  }

  const pdfImage = await pdfDoc.embedPng(imageUint8Array);
  const { width, height } = pdfImage.scale(1);
  let scaledWidth = width;
  let scaledHeight = height;

  if (width > pageWidth || height > pageHeight) {
    const widthRatio = pageWidth / width;
    const heightRatio = pageHeight / height;
    const scaleRatio = Math.min(widthRatio, heightRatio);
    scaledWidth = width * scaleRatio;
    scaledHeight = height * scaleRatio;
  }

  const page = pdfDoc.addPage([pageWidth, pageHeight]);
  page.drawImage(pdfImage, {
    x: (pageWidth - scaledWidth) / 2,
    y: (pageHeight - scaledHeight) / 2,
    width: scaledWidth,
    height: scaledHeight,
  });

  const fileNameNew = !!fileName ? fileName : "Certificate";
  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: "application/pdf" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${fileNameNew}.pdf`;
  link.click();
};
