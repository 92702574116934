import React, { FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Avatar, Button, Grid } from "@material-ui/core";
import { ITask } from "dto/user.dto";
import { IModule } from "dto/module.dto";
import { IChallenger } from "dto/challenger.dto";
import { ILaboratory } from "dto/laboratory.dto";
import * as utils from "utils";
import FormTypography from "components/Form/FormTypography";
import ChallengerButtonLook from "./ChallengerButtonLook";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%", 
    padding: "16px 24px",
    position: "relative",
    borderTop: "2px solid #f0f0f0",
    textAlign: "initial",
    textTransform: "initial",
    "&:hover": { backgroundColor: "#f0f0f0" },
  },
  content: { gap: 16, alignItems: "center" },
  avatar: {
    width: 84,
    height: 84,
    borderRadius: 12,
  },
  data: { width: "calc(100% - 100px)" },
  info: { gap: 12, alignItems: "center" },
  rate: { gap: 4, width: "fit-content", alignItems: "center" },
}));

interface IProps {
  tasks: ITask[];
  module: IModule;
  challenger: IChallenger;
  laboratory: ILaboratory;
  selectChallenger: (value: IChallenger) => void;
}

const ChallengerButton: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [challengerImageUrl, setChallengerImageUrl] = useState("/img/challenger/default.svg");
  const task = utils.getChallengerTask(
    props.laboratory?.name, 
    props.module?.name, 
    props.challenger?.name,
    props.tasks,
  );
  const questions = !!task?.questions?.length 
    ? task.questions 
    : props.challenger?.questions;
  const enabled = utils.getChallengerSequentialEnabled(
    props.laboratory?.name, 
    props.module, 
    props.challenger,
    props.tasks,
  );
  const finished = utils.getChallengerFinished(
    props.laboratory?.name, 
    props.module?.name, 
    props.challenger?.name,
    props.tasks,
  );
  const challengerLocked = !utils.getChallengerUnlocked(
    props.laboratory,
    props.module, 
    props.challenger, 
    props.tasks,
  );
  const titleColor = !!enabled && !challengerLocked ? "#5f5f5f" : "#929292"; 
  const displayName = utils.getDisplayName(props.challenger);

  useEffect(() => {
    getChallengerImageUrl(props.laboratory?.name, props.module?.name, props.challenger);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.challenger]);

  const getChallengerImageUrl = async (
    laboratoryName: string, 
    moduleName: string,
    challenger: IChallenger,
  ) => {
    if (challenger?.image !== "challenger_image_default.svg") {
      const image_url = await utils.downloadFile(
        `${laboratoryName}/${moduleName}/challenges`,
        challenger?.image,
      );
      if (!!image_url) setChallengerImageUrl(image_url);
      else setChallengerImageUrl("/img/challenger/default.svg");
    } else setChallengerImageUrl("/img/challenger/default.svg");
  }

  const selectChallenger = () => props.selectChallenger(props.challenger);

  return (
    <Button 
      className={classes.button} 
      onClick={selectChallenger} 
      disabled={!enabled || !!challengerLocked}
    >
      <Grid className={classes.content} container>
        <Avatar className={classes.avatar} src={challengerImageUrl} alt="" />

        <Grid className={classes.data} item>
          <FormTypography color={titleColor}variant="h5"fontWeight={700}>
            {displayName}
          </FormTypography>
          <Grid className={classes.info} container>
            <FormTypography color="#929292" fontSize={18}>
              {`${questions?.length ?? 0} ${t("Actividades")}`}
            </FormTypography>
            {!!enabled && !!finished && (
              <Grid className={classes.rate} container>
                {!props.challenger?.hide_ratings && (
                  <FormTypography color="#f7a500" variant="h6" fontWeight={700}>
                    {task?.rate ?? 0}
                  </FormTypography>
                )}
                <img src="/img/challenger/rate.svg" alt="" />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <ChallengerButtonLook look={!enabled || !!challengerLocked} finished={finished} />
    </Button>
  )
}

export default ChallengerButton;
