import React, { FC, useRef } from "react";
import { Line } from "react-konva";
import { ITemplateItem } from "dto/template.dto";

interface IProps {
  templateItem: ITemplateItem;
}

const TemplateItemRegularPolygon: FC<IProps> = (props) => {
  const refLine = useRef<any>();

  return (
    <Line
      x={props.templateItem.x}
      y={props.templateItem.y}
      id={props.templateItem.id}
      ref={refLine}
      fill={props.templateItem?.fill}
      closed={props.templateItem?.closed}
      points={props.templateItem?.points}
      stroke={props.templateItem?.stroke}
      scaleX={props.templateItem?.scaleX}
      scaleY={props.templateItem?.scaleY}
      rotation={props.templateItem?.rotation}
    />
  );
};

export default TemplateItemRegularPolygon;
