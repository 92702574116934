import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { ILaboratoryCongratulations } from "dto/laboratory.dto";
import FormTypography from "components/Form/FormTypography";
import CircularProgressBar from "./CircularProgressBar";

const useStyles = makeStyles((theme) => ({
  header: {
    height: 110,
    border: "2px solid #f0f0f0",
    padding: "8px 32px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: { width: "calc(100% - 90px)" },
}));

interface IProps {
  rate: number;
  subtitle?: string;
  hideRatings?: boolean;
  primaryColor?: string;
  congratulations: ILaboratoryCongratulations;
}

const SuccessHeader: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const congratulationTitle = props.rate > 2 || !!props.hideRatings
    ? props.congratulations?.complete_text_one 
    : props.congratulations?.incomplete_text_one;
  const congratulationSubtitle = props.rate > 2 || !!props.hideRatings
    ? props.congratulations?.complete_text_two 
    : props.congratulations?.incomplete_text_two;

  return (
    <Grid className={classes.header} container>
      <Grid className={classes.title} item>
        <FormTypography variant="h5" fontWeight={700} noWrap>
          {t(congratulationTitle)}
        </FormTypography>
        <FormTypography color="#8d8d8d" noWrap>
          {t(congratulationSubtitle)}
        </FormTypography>
        {!!props.subtitle && (
          <FormTypography color="#8d8d8d" variant="caption" fontWeight={700}>
            {t(props.subtitle)}
          </FormTypography>
        )}
      </Grid>
      {!props.hideRatings && (
        <CircularProgressBar rate={props.rate} color={props.primaryColor} />
      )}
    </Grid>
  )
}

export default SuccessHeader;
