import React, { FC, useRef } from "react";
import { RegularPolygon } from "react-konva";
import { ITemplateItem } from "dto/template.dto";

interface IProps {
  templateItem: ITemplateItem;
}

const TemplateItemRegularPolygon: FC<IProps> = (props) => {
  const refRegularPolygon = useRef<any>();

  return (
    <RegularPolygon
      x={props.templateItem.x}
      y={props.templateItem.y}
      id={props.templateItem.id}
      ref={refRegularPolygon}
      fill={props.templateItem?.fill}
      sides={props.templateItem?.sides ?? 0}
      radius={props.templateItem?.radius ?? 0}
      rotation={props.templateItem?.rotation}
    />
  );
};

export default TemplateItemRegularPolygon;
