import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { history } from "configureStore";
import { ITask } from "dto/user.dto";
import { State } from "store/types";
import { IModule } from "dto/module.dto";
import { IChallenger } from "dto/challenger.dto";
import { ILaboratory } from "dto/laboratory.dto";
import { IRouterParams } from "dto/router.dto";
import { entityInit } from "initials/entity.init";
import { challengerInit, laboratoryInit, moduleInit } from "initials";
import * as utils from "utils";
import * as actions from "store/actions";
import Page404 from "components/Pages/Page404";
import Challenger from "components/Challenger/Challenger";
import PageLayout from "components/Pages/PageLayout";
import ModulesList from "components/Laboratory/ModulesList";
import { messageError } from "components/Common/Toast";
import LaboratoryButton from "components/Home/LaboratoryButton";

interface IProps extends RouteComponentProps<IRouterParams> {}

const LaboratoryWithExpirationTime: FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state: State) => state.user.user);
  const taskLoading = useSelector((state: State) => state.user.taskLoading);
  const anonymousUser = useSelector((state: State) => state.anonymousUser.anonymousUser);
  const dialogSuccess = useSelector((state: State) => state.home.modalSuccess);
  const [zoom, setZoom] = useState(false);
  const [entity, setEntity] = useState(entityInit);
  const [module, setModule] = useState(moduleInit);
  const [loading, setLoading] = useState(false);
  const [challenger, setChallenger] = useState(challengerInit);
  const [laboratory, setLaboratory] = useState(laboratoryInit);
  const [modulesList, setModulesList] = useState(false);
  const [laboratoryBackgroundVideoAutoplay, setLaboratoryBackgroundVideoAutoplay] = useState(false);
  const expirationTime = Number(props.match?.params?.expiration_time);
  const notExpiration = new Date()?.getTime() < new Date(expirationTime)?.getTime();
  const page404Title = !notExpiration ? "El link ha expirado" : undefined;
  const page404SubTitle = !notExpiration 
    ? "El link de acceso ha expirado o ha sido borrado" 
    : undefined;
  const tasks = !!user?.tasks?.length
    ? user?.tasks
    : !!anonymousUser?.tasks?.length
    ? anonymousUser?.tasks 
    : [];
  const notebook = !!module?.name && !!challenger?.name;
  const backgroundImage = !!notebook && !!module?.background_image_url 
    ? module.background_image_url 
    : !!modulesList
    ? laboratory?.background_image_url 
    : !!entity?.background_image_url 
    ? entity?.background_image_url 
    : "";
  const backgroundVideo = !!notebook && !!module?.background_video_url 
    ? module.background_video_url
    : !!modulesList && !!laboratory?.background_video_url 
    ? laboratory?.background_video_url 
    : "";
  const backgroundVideoAudio = !!notebook && !!module?.background_video_url 
    ? !!module?.background_video_url
    : laboratory?.background_video_audio;
  const backgroundVideoAutoplay = !!notebook && !!module?.background_video_url 
    ? !!module?.background_video_url 
    : laboratoryBackgroundVideoAutoplay;
  
  useEffect(() => { 
    getLaboratory();
    
    return () => { 
      setLoading(false);
      setLaboratory(laboratoryInit);
    };
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLaboratory = async () => {
    setLoading(true);
    if (!!notExpiration) {
      const laboratoryUuid = props.match?.params?.laboratory_uuid;
      const lab = await utils.getLaboratoryByKey(laboratoryUuid);
      if (lab?.state === "private" && !!user?.nit) {
        // const laboratoryPrivate = await utils.getLaboratoryPrivateWithFiles(lab, user);
        // setLaboratory(laboratoryPrivate);
        // dispatch(actions.analytics("access_laboratory_private", {}));
      } else if (lab?.state === "draft" && !!user?.nit) {
        // const laboratoryDraft = await utils.getLaboratoryDraft(lab, user);
        // setLaboratory(laboratoryDraft);
        // dispatch(actions.analytics("access_laboratory_draft", {}));
      } else if (lab?.state === "public") {
        const laboratoryPublic = await utils.getLaboratoryPublicWithFiles(lab);
        const entityPublic = await utils.getEntityByName({ entityName: laboratoryPublic?.sponsor });
        setEntity(entityPublic?.entity);
        setLaboratory(laboratoryPublic);
        dispatch(actions.analytics("access_laboratory_public", {}));
      }
    }
    setLoading(false);
  }
  
  const closeZoom = () => setZoom(false);
  const toogleZoom = () => setZoom((v) => !v);
  const openDialogSuccess = () => dispatch(actions.openModalSuccess());

  const saveUserTask = (value: ITask) => {
    const task: ITask = {
      ...value,
      date: new Date().getTime().toString(),
      module: module?.name,
      laboratory: laboratory?.name,
      challenger: challenger?.name,
    }
    const state = laboratory?.state;
    if (state === "private" && !!user?.nit && !!challenger?.selected) {
      // dispatch(actions.updateUserTask(task, tasks, deselectChallenger));
    } else if (state === "private" && !!user?.nit) {
      // createUserTask(task);
    } else if (state === "draft" && !!user?.nit && !!challenger?.selected) {
      // updateUserTaskDraft(task);
    } else if (state === "draft" && !!user?.nit) {
      // createUserTaskDraft(task);
    } else if (state === "public" && !!user?.nit && !!challenger?.selected) {
      dispatch(actions.updateUserTask(task, tasks, deselectChallenger));
    } else if (state === "public" && !!user?.nit) {
      createUserTask(task);
    } else if (state === "public" && !!anonymousUser?.uuid && !!challenger?.selected) {
      dispatch(actions.updateUserAnonymousTask(task, tasks, deselectChallenger));
    } else if (state === "public" && !!anonymousUser?.uuid) {
      createUserAnonymousTask(task);
    } else if (state === "public" && challenger?.selected) {
      //
    } else if (state === "public") {
      createUserAnonymousWithTasks(task);
    }
  }
  const createUserTask = (task: ITask) => {
    dispatch(actions.createUserTask({
      task, 
      tasks, 
      module: module, 
      challenger: challenger,
      laboratory: laboratory,
      createTaskCallback,
    }));
  }
  const createTaskCallback = (next?: IChallenger, type?: string, task?: ITask) => {
    if (!!next) {
      setChallenger(next);
    } else if (type === "finished") {
      deselectModule();
      dispatch(actions.analytics("perform_laboratory_module"));
    } else deselectChallenger();

    if (!!task) getActionLaboratoryCompleted(task);
  }
  const createUserAnonymousTask = (task: ITask) => {
    dispatch(actions.createUserAnonymousTask({
      task, 
      tasks, 
      module: module, 
      challenger: challenger, 
      laboratory: laboratory,
      createTaskCallback,
    }));
  }
  const createUserAnonymousWithTasks = (task: ITask) => {
    dispatch(actions.createUserAnonymousWithTasks({
      task,
      tasks,
      module: module,
      challenger: challenger,
      laboratory: laboratory,
      createTaskCallback,
    }));
  }
  const getActionLaboratoryCompleted = (task: ITask) => {
    const completed = utils.getLaboratoryCompletedUsingProgress(
      laboratory, 
      module,
      challenger, 
      [...tasks, task],
    );
    const moduleLast = laboratory?.modules[laboratory?.modules?.length - 1];
    const challengerLast = moduleLast?.challenges[moduleLast?.challenges?.length - 1];
    const condition = 
      completed &&
      moduleLast?.name === module?.name && 
      challengerLast?.name === challenger?.name;
    const params = new URLSearchParams(window.location.search);
    const back_url = params.get("back_url");
    const queryString = window.location.search.substring(1);
    const paramsArray = queryString.split("&");
    let queryStringNew = "";
    const paramsArrayNew = paramsArray?.filter((p) => (!p?.includes("back_url")));
    for (let i = 0; i < paramsArrayNew?.length; i++) {
      if (!!queryStringNew) {
        queryStringNew = `${queryStringNew}&${paramsArrayNew[i]}`;
      } else queryStringNew = `${queryStringNew}${paramsArrayNew[i]}`;
    }
    const laboratoryRate = utils.getLaboratoryRate(laboratory, [...tasks, task]);

    if (laboratory?.state === "public") {
      if (condition && !!back_url && !!queryStringNew) {
        window.open(`${back_url}?${queryStringNew}&rate=${laboratoryRate}`, "_self");
      } else if (condition && !!user?.nit) {
        history.push("/home"); 
      } else if (condition && !!anonymousUser?.uuid && !!anonymousUser?.userType) {
        history.push("/explore");
      } else if (condition && !!anonymousUser?.uuid) {
        history.push("/anonymous-user");
      } else if (condition) history.push("/anonymous-user");
    } else if (laboratory?.state === "private") {
      // if (condition && !!user?.nit && !!laboratory?.sequential_mode) {
      //   history.push("/home");
      // }
    }
  }

  const selectModule = (value: IModule) => {
    if (value?.challenges?.length) setModule(value);
    else messageError(t("El módulo no tiene unidades"));
  }
  const deselectModule = () => {
    deselectChallenger();
    setModule(moduleInit);
    !!zoom && closeZoom();
  }
  const selectChallenger = (value: IChallenger) => {
    const aux = utils.getChallengerWithTasks(laboratory.name, module, tasks);
    if (value?.name === aux?.name && value?.questions?.length === aux?.questions?.length) {
      setChallenger(aux);
    } else setChallenger({ ...value, selected: true });
  }
  const deselectChallenger = () => setChallenger(challengerInit);
  const selectLaboratory = () => {
    setModulesList(true);
    setLaboratoryBackgroundVideoAutoplay(laboratory?.background_video_autoplay);
  }
  const deselectLaboratory = () => {
    setModulesList(false);
    setLaboratoryBackgroundVideoAutoplay(false);
  }

  const handleAnalytics = (name: string, params?: { [key: string]: string }) => {
    dispatch(actions.analytics(name, params));
  }

  const generateLaboratoryCertificate = (laboratory: ILaboratory) => {
    dispatch(actions.generateLaboratoryCertificate(laboratory));
  }

  const toogleLaboratoryBackgroundVideoAutoplay = () => {
    setLaboratoryBackgroundVideoAutoplay((v) => !v);
  }

  return (
    <PageLayout
      zoom={zoom} 
      loading={loading}
      topBarColor={entity?.top_bar_color}
      backgroundGif
      backgroundImage={backgroundImage}
      backgroundVideo={backgroundVideo}
      organizationImage={entity?.organization_image_url}
      backgroundVideoAudio={backgroundVideoAudio}
      backgroundVideoAutoplay={backgroundVideoAutoplay}
    >
      {!!notebook ? (
        <Challenger
          zoom={zoom} 
          tasks={tasks}
          module={module}
          challenger={challenger}
          laboratory={laboratory}
          taskLoading={taskLoading}
          dialogSuccess={dialogSuccess}
          languageAudio={laboratory?.language ?? ""}
          toogleZoom={toogleZoom}
          saveUserTask={saveUserTask}
          handleAnalytics={handleAnalytics}
          openDialogSuccess={openDialogSuccess}
          deselectChallenger={deselectChallenger}
        />
      ) : !!modulesList ? (
        <ModulesList
          tasks={tasks}
          laboratory={laboratory}
          userCurrent={user}
          moduleCurrent={module}
          onClickBack={deselectLaboratory}
          selectModule={selectModule}
          deselectModule={deselectModule}
          selectChallenger={selectChallenger}
          generateLaboratoryCertificate={generateLaboratoryCertificate}
          toogleBackgroundVideoAutoplay={toogleLaboratoryBackgroundVideoAutoplay}
        />
      ) : !!laboratory?.name ? (
        <LaboratoryButton
          user={user}
          tasks={tasks}
          laboratory={laboratory} 
          entityName={entity?.name}
          selectContent={selectLaboratory}
          generateLaboratoryCertificate={generateLaboratoryCertificate}
        />
      ) : (
        <Page404 title={page404Title} subTitle={page404SubTitle} />
      )}
    </PageLayout>
  )
}

export default LaboratoryWithExpirationTime;
