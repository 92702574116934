import React, { FC, useRef } from "react";
import { Rect } from "react-konva";
import { ITemplateItem } from "dto/template.dto";

interface IProps {
  templateItem: ITemplateItem;
}

const TemplateItemRect: FC<IProps> = (props) => {
  const refRect = useRef<any>();

  return (
    <Rect
      x={props.templateItem.x}
      y={props.templateItem.y}
      id={props.templateItem.id}
      ref={refRect}
      fill={props.templateItem?.fill}
      width={props.templateItem.width}
      height={props.templateItem.height}
      stroke={props.templateItem?.stroke}
      rotation={props.templateItem?.rotation}
      strokeWidth={props?.templateItem?.stroke_width}
      cornerRadius={props.templateItem?.corner_radius}
    />
  );
};

export default TemplateItemRect;
