import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, Grid } from "@material-ui/core";
import { ITask } from "dto/user.dto";
import { IModule } from "dto/module.dto";
import { IChallenger } from "dto/challenger.dto";
import { ILaboratory } from "dto/laboratory.dto";
import ChallengerButton from "components/Laboratory/ChallengerButton";
import DialogChallengesHeader from "components/Laboratory/DialogChallengesHeader";

const useStyles = makeStyles((theme) => ({
  dialog: { 
    "& .MuiDialog-paper": { 
      width: "100%",
      minHeight: 300,
      borderRadius: 16,
      backgroundColor: "white",
    }, 
  },
  header: { padding: "16px 24px" },
  content: {
    height: 355,
    overflow: "auto",
    alignItems: "flex-start",
    "&::-webkit-scrollbar": { width: 0 },
  },
}));

interface IProps {
  open: boolean;
  tasks: ITask[];
  laboratory: ILaboratory;
  moduleCurrent: IModule;
  onClose: () => void;
  selectChallenger: (value: IChallenger) => void;
}

const DialogChallenges: FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Dialog className={classes.dialog} open={props.open} onClose={props.onClose}>
      <DialogChallengesHeader module={props.moduleCurrent} />
      
      <Grid className={classes.content} container>
        <Grid container>
        {props.moduleCurrent?.challenges?.map((challenger, key) => (
          <Grid key={key} item xs={12}>
            <ChallengerButton 
              tasks={props.tasks}
              module={props.moduleCurrent}
              challenger={challenger}
              laboratory={props.laboratory}
              selectChallenger={props.selectChallenger}
            />
          </Grid>
        ))}
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default DialogChallenges;
