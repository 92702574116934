import React, { FC } from "react";
import useImage from "use-image";
import { Image } from "react-konva";
import { ITemplateItem } from "dto/template.dto";

interface IProps {
  refStage?: any;
  templateItem: ITemplateItem;
  selectTemplateItem?: (templateItem: ITemplateItem) => void;
}

const TemplateItemPng: FC<IProps> = (props) => {
  const [image] = useImage(props.templateItem?.image_url ?? "", "anonymous");

  const onClick = () => {
    !!props.selectTemplateItem && props.selectTemplateItem(props.templateItem);
  }

  const onMouseEnter = () => {
    if (!!props.templateItem?.skip_logic && !!props.refStage?.current) {
      props.refStage.current.container().style.cursor = "pointer";
    }
  }
  const onMouseLeave = () => {
    if (!!props.refStage?.current) {
      props.refStage.current.container().style.cursor = "default";
    }
  }

  return (
    <Image
      x={props.templateItem.x}
      y={props.templateItem.y}
      id={props.templateItem.id}
      image={image}
      width={props.templateItem.width}
      height={props.templateItem.height}
      scaleX={props.templateItem?.scaleX}
      scaleY={props.templateItem?.scaleY}
      onTap={onClick}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};

export default TemplateItemPng;
