import React, { FC, useRef } from "react";
import {  Path } from "react-konva";
import { ITemplateItem } from "dto/template.dto";

interface IProps {
  templateItem: ITemplateItem;
}

const TemplateItemPath: FC<IProps> = (props) => {
  const refPath = useRef<any>();

  return (
    <Path
      x={props.templateItem.x}
      y={props.templateItem.y}
      id={props.templateItem.id}
      ref={refPath}
      data={props.templateItem?.path}
      fill={props.templateItem?.fill}
      stroke={props.templateItem?.stroke}
      scaleX={props.templateItem?.scaleX}
      scaleY={props.templateItem?.scaleY}
      rotation={props.templateItem?.rotation}
      strokeWidth={props.templateItem?.stroke_width}
    />
  );
};

export default TemplateItemPath;
