import React, { FC, useRef } from "react";
import { Circle } from "react-konva";
import { ITemplateItem } from "dto/template.dto";

interface IProps {
  templateItem: ITemplateItem;
}

const TemplateItemCircle: FC<IProps> = (props) => {
  const refCircle = useRef<any>();

  return (
    <Circle
      x={props.templateItem.x}
      y={props.templateItem.y}
      id={props.templateItem.id}
      ref={refCircle}
      fill={props.templateItem?.fill}
      radius={props.templateItem?.radius}
    />
  )
};

export default TemplateItemCircle;
