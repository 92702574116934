import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { State } from "store/types";
import { IQuestion } from "dto/question.dto";
import { IRouterParams } from "dto/router.dto";
import { entityInit } from "initials/entity.init";
import { surveyInit } from "initials";
import * as utils from "utils";
import * as actions from "store/actions";
import Survey from "components/Survey/Survey";
import Page404 from "components/Pages/Page404";
import PageLayout from "components/Pages/PageLayout";
import SurveyButton from "components/Home/SurveyButton";

interface IProps extends RouteComponentProps<IRouterParams> {}

const SurveyWithExpirationTime: FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const languageAudio = useSelector((s: State) => s.user.languageAudio);
  const saveAnswerLoading = useSelector((state: State) => state.survey.saveAnswerLoading);
  const dialogCongratulations = useSelector((s: State) => s.survey.dialogCongratulations);
  const [zoom, setZoom] = useState(false);
  const [entity, setEntity] = useState(entityInit);
  const [survey, setSurvey] = useState(surveyInit);
  const [loading, setLoading] = useState(false);
  const [notebook, setNotebook] = useState(false);
  const expirationTime = Number(props.match?.params?.expiration_time);
  const notExpiration = new Date()?.getTime() < new Date(expirationTime)?.getTime();
  const page404Title = !notExpiration ? "El link ha expirado" : undefined;
  const page404SubTitle = !notExpiration 
    ? "El link de acceso ha expirado o ha sido borrado" 
    : undefined;
  const backgroundImage = !!survey.privacy
    ? "/img/background.svg"
    : !!survey.properties.wallpaper_url && !!notebook
    ? survey.properties.wallpaper_url
    : !!entity?.background_image_url 
    ? entity.background_image_url
    : "/img/background/entorno_b1.png";
  const backgroundVideo = !!survey.privacy 
    ? "" 
    : !!notebook && !!survey?.properties?.background_video_url 
    ? survey.properties.background_video_url 
    : "";

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getSurveyPublic(); }, []);
  
  const getSurveyPublic = async () => {
    setLoading(true);
    if (!!notExpiration) {
      const surveyUuid = props.match?.params?.survey_uuid;
      const surveydata = await utils.getSurveyByUuid(surveyUuid);
      if (!surveydata?.privacy) {
        const entityData = await utils.getEntityByName({ entityName: surveydata?.sponsor });
        setEntity(entityData?.entity);
        setSurvey(surveydata);
      }
    }
    setLoading(false);
  }

  const onClickZoom = () => setZoom(!zoom);
  const openNotebook = () => setNotebook(true);
  const closeNotebook = () => setNotebook(false);

  const handleAnalytics = (name: string, params?: { [key: string]: string }) => {
    dispatch(actions.analytics(name, params));
  }

  const saveSurveyAnswers = (value: IQuestion[]) => {
    dispatch(actions.saveSurveyAnswers(survey.uuid, value));
  }

  const closeCongratulations = () => dispatch(actions.closeCongratulations());

  return (
    <PageLayout
      zoom={zoom} 
      loading={loading}
      topBarColor={entity?.top_bar_color}
      backgroundGif
      backgroundImage={backgroundImage}
      backgroundVideo={backgroundVideo}
      organizationImage={entity?.organization_image_url}
      backgroundVideoAudio
      backgroundVideoAutoplay
    >
      {!!notebook && !survey?.privacy && !!survey?.questions?.length ? (
        <Survey 
          zoom={zoom} 
          survey={survey} 
          questions={survey?.questions}
          languageAudio={String(languageAudio)}
          saveAnswerLoading={saveAnswerLoading}
          dialogCongratulations={dialogCongratulations}
          onClickZoom={onClickZoom}
          closeSurveyBook={closeNotebook}
          handleAnalytics={handleAnalytics}
          saveSurveyAnswers={saveSurveyAnswers}
          closeCongratulations={closeCongratulations}
        />
      ) : !survey?.privacy && !!survey?.questions?.length ? (
        <SurveyButton survey={survey} selectSurvey={openNotebook} />
      ) : (
        <Page404 title={page404Title} subTitle={page404SubTitle} />
      )}
    </PageLayout>
  )
}

export default SurveyWithExpirationTime;
